import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { required, email } from 'vuelidate/lib/validators';
import { Validate } from 'vuelidate-property-decorators';
import AuthInput from '@/components/auth/AuthInput.vue';
import LicenseApi from '@/services/api/license.api';
let AuthTrialPage = class AuthTrialPage extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.firstName = '';
        this.lastName = '';
        this.email = '';
    }
    async enterLicense() {
        this.$router.replace({
            name: 'auth.license',
        });
    }
    async submit() {
        try {
            this.$v.$touch();
            if (this.$v.$error) {
                return;
            }
            this.loading = true;
            const { firstName, lastName, email } = this;
            await LicenseApi.getTrial({
                firstName,
                lastName,
                email,
            });
            this.$toast.success('Licence key was sent to your email.');
            this.$router.replace({
                name: 'auth.license',
            });
        }
        catch (error) {
            this.$toast.error(error.message);
        }
        finally {
            this.loading = false;
        }
    }
};
__decorate([
    Validate({
        required,
    })
], AuthTrialPage.prototype, "firstName", void 0);
__decorate([
    Validate({
        required,
    })
], AuthTrialPage.prototype, "lastName", void 0);
__decorate([
    Validate({
        required,
        email,
    })
], AuthTrialPage.prototype, "email", void 0);
AuthTrialPage = __decorate([
    Component({
        components: {
            AuthInput,
        },
    })
], AuthTrialPage);
export default AuthTrialPage;
